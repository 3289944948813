import React, { useState, useEffect } from 'react';

function TimeCount({ onGetValue }) {
  const [time, setTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
        if(time < 100)
        {
            setTime(time + 4);
        }
        else
        {

        }
      
      onGetValue(time);
    }, 1000);
    setIntervalId(intervalId);
    return () => clearInterval(intervalId);
  }, [time]);

  const handleReset = () => {
    setTime();
  };

  return (
    <>
    {/*<h1>Timer: {time} seconds</h1>
    <div>
      <h1>Timer: {time} seconds</h1>
      {time === 0 ? (
        <p>Time's up!</p>
      ) : (
        <p>
          <button onClick={handleReset}>Reset</button>
        </p>
      )}
    </div>*/}
    </>
  );
}

export default TimeCount;