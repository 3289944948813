import React, { useState, useEffect } from 'react';

function ShowMassage() {
  const texts = [
    'Unlock the Future of Transactions.',
    'Empowering Payments with Crypto.',
    'Fast, Secure, and Crypto-Friendly.',
    'Revolutionize Payments, One Block at a Time.',
    'Crypto Payments Made Simple.',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    setCurrentText(texts[currentIndex]);
  }, [currentIndex]);

  const handleNextText = () => {
    const nextIndex = (currentIndex + 1) % texts.length;
    setCurrentIndex(nextIndex);
  };

  const intervalShow = setInterval(() => {
    handleNextText();
  }, 3000); // 3000ms = 3 seconds

  return (
    <div>
      <small>{currentText}</small>
    </div>
  );
}

export default ShowMassage;

