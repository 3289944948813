import React from 'react';
import BEP20Token from './BEP20Token';

function App() {
  return (
    <div className="App">
      <BEP20Token />
    </div>
  );
}

export default App;
